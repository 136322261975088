<template>
  <b-table
    :data="data.data"
    :striped="true"
    :narrowed="true"
    :sortable="sortable"
    default-sort-direction="asc"
    :sticky-header="data.data.length>perPage"
    :debounce-search="300"
    :paginated="isPaginated"
    :per-page="perPage"
    :current-page="currentPage"
    :height="height"
  >
    <b-table-column
      field="group"
      :label="getField('group').label"
      sortable="sortable"
      :searchable="getField('group').type!=='numeric'&&searchable"
    >
      <template v-slot="props">
        <b-tooltip v-if="props.row.group.length > printLen">
          <template v-slot:content>{{ props.row.group }}</template>{{ props.row.group | subStr }}
        </b-tooltip>
        <span v-else>
          {{ props.row.group }}
        </span>
      </template>
    </b-table-column>
    <b-table-column
      field="conclusion"
      :label="getField('conclusion').label"
    >
      <template v-slot="props">{{ props.row.conclusion }}</template>
    </b-table-column>
    <b-table-column
      field="stat"
      :label="getField('stat').label"
      numeric
      sortable="sortable"
    >
      <template v-slot:header="{ column }">
        <b-tooltip
          v-if="column.label!==getField('stat').labelShort"
          :label="column.label" append-to-body dashed
        >{{ getField('stat').labelShort }}</b-tooltip>
        <span v-else>{{ column.label }}</span>
      </template>
      <template v-slot="props">{{ props.row.stat }}</template>
    </b-table-column>
    <b-table-column
      field="pval"
      :label="getField('pval').label"
      numeric
      sortable="sortable"
    >
      <template v-slot="props">
        <div style="text-align: right;">{{ props.row.pval | scientific }}</div>
      </template>
    </b-table-column>
    <b-table-column v-if="getField('pvaladj')"
      field="pvaladj"
      :label="getField('pvaladj').label"
      numeric
      sortable="sortable"
    >
      <template v-slot="props">{{ props.row.pvaladj | scientific }}</template>
    </b-table-column>
    <b-table-column field="stars" width="75">
      <template v-slot="props">
        <statistical-test-stars :score="props.row.stars" v-if="props.row.stars !=='NA'"/>
      </template>
    </b-table-column>
  </b-table>
</template>

<script>
import { table } from '@/mixins/table'
export default {
  name: 'PostHocTests',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  mixins: [table],
  components: {
    'statistical-test-stars': () => import('@/components/ui/StatisticalTestStars.vue')
  },
  methods: {
    getField: function (field) {
      return this.data.fields.filter(obj => obj.field === field)[0]
    }
  }
}
</script>
